// src/App.js
import React, { useEffect } from "react";
import { Routing } from "./routes/Routing";
import ReactGA from "react-ga";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { ToastProvider } from "./ToastContext"; // Ensure the path is correct

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-D3YYY1TKVY");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <ToastProvider>
      <div className="app">
        <Routing />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </ToastProvider>
  );
};

export default App;
