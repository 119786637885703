import React, { useState } from "react";
import Footer from "../../components/nav-bar/Footer";
import "react-phone-number-input/style.css";
import "../../style/ContactUs.css";
import PhoneInputWithCountry from "react-phone-number-input";
import { CircularProgress } from "@mui/material";
import { handleContactUs } from "../../api/SubmitAPI";
import { useToast } from "../../ToastContext";

function ContactUs() {
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!formData.phone.startsWith("+")) {
      toast.error("Please enter a valid phone number in E.164 format.");
      setLoading(false);
      return;
    }

    try {
      const response = await handleContactUs(formData);
      if (response && response.data && response.status === 200) {
        setTimeout(() => {
          setLoading(false);
          setFormData({
            name: "",
            email: "",
            subject: "",
            phone: "",
            message: "",
          });
          toast.success("Form submitted successfully!");
        }, 2000);
      }
    } catch (error) {
      toast.error("Error submitting form. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="contactUs-page">
      <div className="contactUs-container-page">
        <div className="contactUs-first-pages">
          <div className="contactUs-heading">
            <span className="contactus-first-heading">Contact Us</span>
            <p className="para-contactus-head">
              Please fill out the form below to get in touch with us.
            </p>
          </div>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Enter the subject"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <PhoneInputWithCountry
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={(value) => setFormData({ ...formData, phone: value })}
                placeholder="Enter your phone number"
                country="US"
                international
                defaultCountry="US"
                required
                style={{
                  border: "1px solid #BABEBE",
                  borderRadius: "4px",
                  outline: "none",
                  paddingLeft: "8px",
                  backgroundColor: "#F8F8F8",
                }}
              />
            </div>
            <div className="form-group message-textarea-align">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Type your message here."
                required
              ></textarea>
            </div>
            <div className="button-group">
              <button
                type="submit"
                className="submit-button-contactUS"
                disabled={loading} // Disable button when loading
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
        <div style={{ marginTop: "50px" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
