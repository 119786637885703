import React, { useState } from "react";
import "../../style/home.css";
import dashboardImg2 from "../../assets/images/dashboardImg2.png";
import dashboardImg1 from "../../assets/images/dashboardImg1.png";
import circleLine from "../../assets/images/circleLIne.png";
import AboutUs from "./AboutUs";
import Works from "./Works";
import Book from "./Book";
import FAQ from "./FAQ";
import Story from "./Story";
import Testimonial from "./Testimonial";
import Footer from "../../components/nav-bar/Footer";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
import "video-react/dist/video-react.css";
// import { FaTimes } from "react-icons/fa";

function Dashboard() {
  const navigate = useNavigate();
  const [isVideoVisible, setIsVideoVisible] = useState(true);

  const handleClick = () => {
    ReactGA.event({
      category: "Form",
      action: "Navigate to form page",
    });
    navigate("/form");
  };

  // const handleVideoClose = () => {
  //   setIsVideoVisible(false);
  // };

  return (
    <div className="dashboard-page">
      <div className="home-container-page">
        <div className="home-first-pages">
          <div className="home-img-content-box">
            <div className="img-btn-box">
              <div className="imge-circle-view">
                <img
                  src={dashboardImg2}
                  className="img-childern-one-homePage"
                  alt="dashboardImg2"
                />
              </div>
              <div className="content-btn-container">
                <div>
                  <h1 className="home-page-text">
                    Craft Your <span className="span-text-align">Own</span>
                  </h1>
                  <h1 className="second-heading-text-home">Journey Book</h1>
                </div>
                <div style={{ marginTop: 20 }}>
                  <p className="home-page-para-text">
                    Personalized stories celebrating every child's unique path
                  </p>
                </div>
                <div
                  style={{
                    alignContent: "center",
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <button className="custom-button" onClick={handleClick}>
                    Get Started Today
                  </button>
                </div>
              </div>
            </div>
            {isVideoVisible && (
              <div className="video-container">
                {/* <div className="video-close-icon" onClick={handleVideoClose}>
                  <FaTimes />
                </div> */}
                <Player
                  poster="/assets/poster.png"
                  fluid={false}
                  width={620}
                  height={380}
                >
                  <source src="https://stream.journeybookstories.com/home.mp4" />
                  <ControlBar
                    autoHide={true}
                    disableCompletely={true}
                    className="custom-control-bar"
                  >
                    <PlaybackRateMenuButton
                      rates={[5, 2, 1, 0.5, 0.1]}
                      className="custom-playback-rate-btn"
                    />
                    <VolumeMenuButton disabled className="custom-volume-btn" />
                  </ControlBar>
                </Player>
              </div>
            )}
            {isVideoVisible && (
              <div className="text-btn-content-mobile">
                {/* <div className="video-close-icon" onClick={handleVideoClose}>
                  <FaTimes />
                </div> */}
                <Player
                  poster="/assets/poster.png"
                  fluid={false}
                  width={350}
                  height={200}
                >
                  <source src="https://stream.journeybookstories.com/home.mp4" />
                  <ControlBar
                    autoHide={true}
                    disableCompletely={true}
                    className="custom-control-bar"
                  >
                    <PlaybackRateMenuButton
                      rates={[5, 2, 1, 0.5, 0.1]}
                      className="custom-playback-rate-btn"
                    />
                    <VolumeMenuButton disabled className="custom-volume-btn" />
                  </ControlBar>
                </Player>
              </div>
            )}
          </div>
          <div className="home-imgs-content-row">
            <div className="circle-line-img-view">
              <img
                src={circleLine}
                className="img-home-page-circle"
                alt="dashboardImg2"
              />
            </div>
            <div className="imge-circle-view-row">
              <img
                src={dashboardImg1}
                className="img-children-homePage-two"
                alt="dashboardImg2"
              />
            </div>
          </div>
        </div>
        <div>
          <AboutUs />
        </div>
        <div>
          <Works />
        </div>
        <div>
          <Book />
        </div>
        <div>
          <FAQ />
        </div>
        <div>
          <Story />
        </div>
        <div>
          <Testimonial />
        </div>
        <div style={{ marginTop: "50px" }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
