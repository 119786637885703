import React from "react";
import "../../style/footer.css";
import JourneyBooks from "../../assets/images/Journey_Books.png-removebg-preview.png";
import ItemFacebook from "../../assets/images/ItemFackbookLink.png";
import itemX from "../../assets/images/item-X-img.png";
import itemInsta from "../../assets/images/itemInsta.png";
import ItemYoutube from "../../assets/images/ItemYoutube.png";

import { Link } from "react-router-dom";

function Footer() {
  const bookImages = [
    {
      img: ItemFacebook,
      url: "https://www.facebook.com/61563588000593/",
    },
    {
      img: itemInsta,
      url: "https://www.linkedin.com/company/journey-books-stories-of-strength-and-advocacy/about/YouTube",
    },
    {
      img: ItemYoutube,
      url: "https://www.youtube.com/@JourneyBooks",
    },
  ];

  const handleLink = (url) => {
    window.location.href = url;
  };

  return (
    <>
      <div className="footer">
        <div className="footer-top">
          <div className="fooder-first-line">
            <div className="logo-footer-align">
              <div className="footer-logo">
                <img src={JourneyBooks} alt="JourneyBooks" />
              </div>
              <div className="image-row-card">
                {bookImages.map((item, index) => (
                  <div
                    className="image-rows"
                    key={index}
                    onClick={() => handleLink(item.url)}
                  >
                    <img
                      src={item.img}
                      width={"40px"}
                      height={"40px"}
                      alt={`link to ${item.url}`}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="footer-menu">
              <p className="footer-title">Quick Links</p>
              <ul className="footer-link">
                <li className="footer-sub-link">
                  <Link className="footer-router-link" to={"/home"}>
                    Home
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/about-us"}>
                    About Us
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/works"}>
                    How It Works
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/testimonials"}>
                    Testimonial
                  </Link>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <Link className="footer-router-link" to={"/contactUs"}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-services">
              <p className="footer-title">Contact Us</p>
              <ul className="footer-link">
                <li className="footer-sub-link">
                  {" "}
                  <p className="footer-router-link">
                    SOS Publications, the Founder of <br />
                    The Journey Book Process™
                    <br />
                    Journey Book ™<br />
                    3209 S. Galena Ct., Denver, CO 80231
                    <br />
                    United States
                  </p>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <p className="footer-router-link">Phone: (+1) 720-295-5812</p>
                </li>
                <li className="footer-sub-link">
                  <a
                    className="footer-router-link"
                    href="mailto:info@journeybookstories.com"
                    style={{ cursor: "pointer" }}
                  >
                    Email: info@journeybookstories.com
                  </a>
                </li>
                <li className="footer-sub-link">
                  {" "}
                  <a
                    className="footer-router-link"
                    href="https://journeybookstories.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: "pointer" }}
                  >
                    Website: https://journeybookstories.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="fooder-second-line">
            {/* <p className="footer-title">News Letter</p>
						<div className='footer-last-para-text-page'>
							{/* <span className="footer-router-link">
								Our approach to itis unique around know<br />
								work an we know Get hands on the you like
							</span> */}
            {/* </div> */}
            <p className="footer-title">Subscribe</p>
            <div className="email-form">
              <input
                type="email"
                placeholder="Your Email"
                className="email-input"
              />
              <button className="send-button">SEND</button>
            </div>
          </div>
        </div>
        <div className="footer-end">
          <div className="footer-end-sub">
            <div className="footer-last-content-text">
              <span className="end-footer-text-para">
                Copyright © 2024 Journey Book Stories
              </span>
            </div>
            <div className="footer-two-last-para">
              <Link className="footer-router-link" to={"/privacypolicy"}>
                Privacy policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
