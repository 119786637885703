export const Menus = [
	{
		title: "Home",
		path: "home",
		cName: "nav-text",
	},
	{
		title: "About Us",
		path: "about-us",
		cName: "nav-text",
	},
	{
		title: "How It Works",
		path: "works",
		cName: "nav-text",
	},
	{
		title: "Testimonials",
		path: "testimonials",
		cName: "nav-text",
	},

	{
		title: "Contact Us",
		path: "contactUs",
		cName: "nav-text",
	},
];
