import React from 'react'
import "../../style/home.css";
import PageBook from '../../assets/images/PageBook.png'
import check from '../../assets/images/check.png'
import AboutImg from '../../assets/images/about_img..png';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

function Works() {
	const navigate = useNavigate();

	const handleClick = () => {
		ReactGA.event({
			category: 'form',
			action: 'Navigate to form page'
		  });
		navigate("/form");
	  };
	const steps = [
		'Enroll in the Journey Book Academy',
		'Create Your Profile',
		'Tell Your Story',
		'Upload Photos',
		'Review and Approval',
		'Print and Ship'
	];

	return (
		<div className="home-works-container">
			<div className="sub-works-container">
				<div className='aboutUs-contant-home-page'>
					<div className="works-view-row">
						<img src={PageBook} className="page-book-img" alt="PageBook" />
					</div>
					<div style={{ marginTop: '15px' }}>
						<p className='work-heading-label'>
							How it Works
						</p>
					</div>
					<div style={{ marginTop: '15px' }}>
						<span className='para-works-page-text'>
							What is the process of<br />
							making your journey book?
						</span>
					</div>
					<div className="step-tracker">
						{steps.map((step, index) => (
							<div key={index} className="step">
								<img src={check} className="check-img" alt="check" />
								<span className='para-step'>{step}</span>
							</div>
						))}
					</div>
					<div className='custom-btn-align-works' onClick={handleClick}>
						<button className="custom-button">
							Start Now
						</button>
					</div>
				</div>
				<div className="aboutUS-view-row">
					<img src={AboutImg} className="about-img" alt="AboutImg" />
				</div>
			</div>
		</div>
	)
}

export default Works