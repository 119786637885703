import React from "react";
import "../../style/form.css";

function General({ formData, setFormData }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="first-form-container">
      <div className="input-container-name-conatiner">
        <div>
          <label className="label-text-form" htmlFor="childrenName">
            Child’s Name
          </label>
        </div>
        <div className="input-container-name">
          <input
            type="text"
            id="childrenName"
            name="childrenName"
            placeholder="Child’s Name"
            value={formData.childrenName}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="Joys">
          Joys
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="Joys"
            name="Joys"
            placeholder="Joys"
            value={formData.Joys}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="Joys2">
          Joy's 2
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="Joys2"
            name="Joys2"
            placeholder="Joy's 2"
            value={formData.JoysSecond}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="favoritePlace">
          Favorite place
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="favoritePlace"
            name="favoritePlace"
            placeholder="Favorite Place"
            value={formData.favoritePlace}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label
          className="label-text-form"
          htmlFor="FamilyMemberAndRelationship"
        >
          Family member & relationship (example Dominic his brother)
        </label>
        <div className="input-container-name">
          <textarea
            id="FamilyMemberAndRelationship"
            name="FamilyMemberAndRelationship"
            placeholder=" Family member & relationship ( example Dominic his brother)n"
            value={formData.FamilyMemberAndRelationship}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="nickname">
          Nickname
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="nickname"
            name="nickname"
            placeholder="Nickname"
            value={formData.nickname}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="SecondfavoritePlace">
          2nd favorite place
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="SecondfavoritePlace"
            name="SecondfavoritePlace"
            placeholder="2nd favorite place"
            value={formData.SecondfavoritePlace}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="Whynickname">
          Why this nickname?
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="Whynickname"
            name="Whynickname"
            placeholder="Why this nickname?"
            value={formData.Whynickname}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="FavouriteActivity">
          Favourite activity
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="FavouriteActivity"
            name="FavouriteActivity"
            placeholder="Favourite activity"
            value={formData.FavouriteActivity}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="familyMemberOrFriend">
          Name of family member or friend who watches me at this activity.
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="familyMemberOrFriend"
            name="familyMemberOrFriend"
            placeholder="Name of family member or friend who watches me at this activity."
            value={formData.familyMemberOrFriend}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default General;
