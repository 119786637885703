import { Route, Routes, useLocation } from "react-router-dom";
import React from "react";
import Dashboard from "../pages/Dashboard/dashboard";
import AboutUs from "../pages/About Us/AboutUs";
import Main from "../pages/main";
import Works from "../pages/ItWorks/Works";
import Testimonials from "../pages/Testimonials/Testimonials";
import ContactUs from "../pages/ContactUs/ContactUs";
import Form from "../pages/Form/Form";
import FAQMain from "../pages/FAQ/FAQMain";
import Privacypolicy from "../pages/Privacypolicy/Privacypolicy";

export const Routing = () => {
  return (
    <Routes>
      <Route path="/*" element={<Main />} />
    </Routes>
  );
};
export const SubRouting = () => {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route exact path="/*" element={<Dashboard />} />
      <Route exact path="/about-us" element={<AboutUs />} />
      <Route exact path="/works" element={<Works />} />
      <Route exact path="/testimonials" element={<Testimonials />} />
      <Route exact path="/contactUs" element={<ContactUs />} />
      <Route exact path="/form" element={<Form />} />
      <Route exact path="/faq" element={<FAQMain />} />
      <Route exact path="/privacypolicy" element={<Privacypolicy />} />
    </Routes>
  );
};
