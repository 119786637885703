import React from "react";

function Challenge({ formData, setFormData, error }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="first-form-container">
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="TragicIncident">
          Tragic Incident
        </label>
        <div className="input-container-name">
          <textarea
            id="tragicIncident"
            name="tragicIncident"
            placeholder="Tragic Incident"
            value={formData.tragicIncident}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="SufferedFrom">
          I Suffered from
        </label>
        <div className="input-container-name">
          <textarea
            type="text"
            id="iSufferedfrom"
            name="iSufferedfrom"
            placeholder="I Suffered from"
            value={formData.iSufferedfrom}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <div>
          <label className="label-text-form" htmlFor="Rescuers">
            Rescuers (ie fireman, 1 st responders,)
          </label>
        </div>
        <div className="input-container-name">
          <input
            type="text"
            id="Rescuers"
            name="Rescuers"
            placeholder="Rescuers (ie fireman, 1 st responders,)"
            value={formData.Rescuers}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="guideAppeared">
          A new guide appeared Title (ie Dr,)
        </label>
        <div className="input-container-name">
          <textarea
            type="text"
            id="guideAppeared"
            name="guideAppeared"
            placeholder="A new guide appeared Title (ie Dr,)"
            value={formData.guideAppeared}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="challengeFrequently">
          One challenge I face frequently{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <div className="input-container-name">
          <textarea
            type="text"
            id="challengeFrequently"
            name="challengeFrequently"
            placeholder="One challenge I face frequently"
            value={formData.challengeFrequently}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
          {error && !formData.challengeFrequently && (
            <p className="error-message">
              One challenge I face frequently is required.
            </p>
          )}
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="hospital">
          Number days in hospital
        </label>
        <div className="input-container-name">
          <input
            type="text"
            id="hospital"
            name="hospital"
            placeholder="Number days in hospital"
            value={formData.hospital}
            onChange={handleChange}
            style={{
              width: "608px",
              height: "48px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="activities">
          List of activities that had to learn again
        </label>
        <div className="input-container-name">
          <textarea
            type="text"
            id="activities"
            name="activities"
            placeholder="List of activities that had to learn again"
            value={formData.activities}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="strengths">
          We worked on “regaining my strengths etc”
        </label>
        <div className="input-container-name">
          <textarea
            type="text"
            id="strengths"
            name="strengths"
            placeholder="We worked on “regaining my strengths etc"
            value={formData.strengths}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
        </div>
      </div>
      <div className="input-container-name-conatiner">
        <label className="label-text-form" htmlFor="accident">
          I am different now than before my accident{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <div className="input-container-name">
          <textarea
            id="accident"
            name="accident"
            placeholder="I am different now than before my accident"
            value={formData.accident}
            onChange={handleChange}
            maxLength={250}
            style={{
              width: "608px",
              height: "144px",
              paddingBottom: "50px",
              border: "1px solid #BABEBE",
              borderRadius: "4px",
              padding: "10px",
              backgroundColor: "#F8F8F8",
            }}
          />
          {error && !formData.accident && (
            <p className="error-message">
              I am different now than before my accidents required.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Challenge;
