import React from 'react';
import "../../style/Works.css";

import storyBooks from '../../assets/images/Story-img.webp';
import EditImg from '../../assets/images/review-icon-edit.png';
import BookReady from '../../assets/images/book-icon.jpg';
import OrderIcon from '../../assets/images/order-icon.jpg';
import SmilyImg from '../../assets/images/smily-img.png';
import AdventureImg from '../../assets/images/adventures-img.png';
import Footer from '../../components/nav-bar/Footer';

function Works() {
  const steps = [
    {
      icon: storyBooks,
      heading: "Personalize Your Story",
      description: "Customize your chosen story template by adding names, places, and special details. Make the adventure truly your own by tailoring it to your unique preferences."
    },
    {
      icon: EditImg,
      heading: "Review and Edit",
      description: "Preview your personalized story and make any necessary edits. Our user-friendly interface allows you to easily make changes and ensure everything is perfect."
    },
    {
      icon: BookReady,
      heading: "Choose Your Format",
      description: "Decide how you'd like to receive your story. Opt for a beautifully printed book, a convenient e-book, or both! Our high-quality printing and digital options ensure your story looks stunning in any format."
    },
    {
      icon: OrderIcon,
      heading: "Place Your Order",
      description: "Once you're happy with your story, place your order with a few simple clicks. Our secure checkout process ensures your personal information is protected."
    },
    {
      icon: SmilyImg,
      heading: "Enjoy Your Story",
      description: "Sit back and relax as we take care of the rest. Your personalized story will be delivered to your doorstep or inbox, ready for you to enjoy and share with others."
    },
    {
      icon: AdventureImg,
      heading: "Start Your Adventure Today!",
      description: "Ready to create your own journey book story? Click below to get started and dive into a world of endless possibilities."
    },
  ];

  return (
    <div className='WorksPage-page'>
      <div className="WorksPage-container-page">
        <div className="WorksPage-first-pages">
          <div className='workPage-Card-heading'>
            <div className='workPage-heading-container'>
              <p className='workPage-heading-text'>How does it Work?</p>
            </div>
            <div className='steps-container'>
              {steps.map((step, index) => (
                <div key={index} className='step-card'>
                  <div className='step-icon'>
                    <img
                      src={step.icon}
                      width={step.icon === storyBooks ? 70 : step.icon === AdventureImg ? 100 : 60}
                      alt='img'
                    />
                  </div>
                  <p className='step-heading'>{step.heading}</p>
                  <p className='step-description'>
                    <span className='span-text-description'>Description:</span> {step.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ marginTop: '50px' }}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Works;
