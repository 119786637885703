import "../../style/home.css";
import AboutUsImg from "../../assets/images/HomeAboutUs-img.png";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

function AboutUs() {
  const navigate = useNavigate();

  const handleClick = () => {
    ReactGA.event({
      category: "AboutUs",
      action: "Navigate to AboutUs page",
    });
    navigate("/about-us");
  };

  return (
    <div className="home-aboutUs-container">
      <div className="sub-aboutUs-container">
        <div className="aboutUS-view-row">
          <img
            src={AboutUsImg}
            className="aboutUs-img-first"
            alt="dashboardImg2"
          />
        </div>
        <div className="content-aboutUs-text-container">
          <div className="heading-aboutUS-paras-text">
            <p className="aboutUs-text-para-heading">Welcome to Journey Book Process</p>
          </div>
          <div className="second-heading-aboutUS-para">
            <p className="aboutUs-text-paras">
            In the heart of every family lies a unique story, a journey filled with hope, challenges, and triumphs. 
            The Journey Book Process captures and shares these powerful narratives, especially for families of children with special needs. 
            We understand the profound love and deep concern you have for your child’s journey. We’re here to help you tell your child’s story with compassion and clarity.
            </p>
          </div>
          <div className="btn-aboutUs-align">
            <button className="custom-button-aboutUs" onClick={handleClick}>
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
