import React, { useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import General from "./General";
import Challenge from "./Challenge";
import Upload from "./Upload";
import SuccessMessage from "./SuccessMessage";
import Footer from "../../components/nav-bar/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import {
  TbCircleNumber1,
  TbCircleNumber2,
  TbCircleNumber3,
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
} from "react-icons/tb";
import { handleFormSubmitted } from "../../api/SubmitAPI";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress
import { useToast } from "../../ToastContext";

const SITE_KEY = "6LftUw4qAAAAAHVMfpFyWTZb2zT49krzoPK8eSID";

function FormValid() {
  const toast = useToast();
  const recaptchaRef = useRef(null);
  const [activeSection, setActiveSection] = useState("section1");
  const [formData, setFormData] = useState({
    childrenName: "",
    Joys: "",
    Joys2: "",
    favoritePlace: "",
    FamilyMemberAndRelationship: "",
    nickname: "",
    SecondfavoritePlace: "",
    Whynickname: "",
    FavouriteActivity: "",
    familyMemberOrFriend: "",
    tragicIncident: "",
    iSufferedfrom: "",
    Rescuers: "",
    guideAppeared: "",
    challengeFrequently: "",
    Hospital: "",
    activities: "",
    strengths: "",
    accident: "",
  });
  const [loading, setLoading] = useState(false);
  const [showUploadError, setShowUploadError] = useState(false);
  const [isSectionOne, setIsSectionOne] = useState(false);
  const [isSectionTwo, setIsSectionTwo] = useState(false);
  const [files, setFiles] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [error, setErorr] = useState(false);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleContinueClick = async (e) => {
    e.preventDefault();
    let isValid = true;
    if (activeSection === "section1") {
      if (isValid) setIsSectionOne(true);
    } else if (activeSection === "section2") {
      const requiredFields = ["challengeFrequently", "accident"];
      const emptyFields = requiredFields.filter((field) => !formData[field]);
      if (emptyFields.length > 0) {
        setErorr(true);
        isValid = false;
      } else {
        setIsSectionTwo(true);
      }
    } else if (activeSection === "section3") {
      if (files.length === 0) {
        toast.error("Please upload at least one file.");
        setShowUploadError(true);
        isValid = false;
      }
    }

    if (isValid) {
      try {
        if (activeSection === "section1") {
          setActiveSection("section2");
        } else if (activeSection === "section2") {
          setActiveSection("section3");
        } else if (activeSection === "section3") {
          const isSection2Valid = ["challengeFrequently", "accident"].every(
            (field) => formData[field]
          );
          if (isSection2Valid && files.length > 0) {
            const base64Files = await Promise.all(
              files.map((fileObj) => convertToBase64(fileObj.file))
            );
            const cleanedBase64Files = base64Files.map(
              (file) => file.split(",")[1]
            );
            const data = {
              ...formData,
              childrenName: formData.childrenName,
              guideName: formData.guideName,
              File: cleanedBase64Files,
              captcha: captchaValue,
            };
            setLoading(true);
            const response = await handleFormSubmitted(data);
            if (response && response.status === 200 && response.data) {
              setFiles([]);
              setFormData({});
              setLoading(false);
              setErorr(false);
              setActiveSection("section4");
            } else {
              toast.error("Error during submission. Please try again.");
              setLoading(false);
            }
          } else {
            toast.error(
              "Please fill in all required fields and upload at least one file."
            );
            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error, "erorr");
        toast.error("Error during submission. Please try again.");
        setLoading(false);
      }
    }
  };

  const handleClearClick = () => {
    setFormData((prevData) => {
      switch (activeSection) {
        case "section1":
          return {
            ...prevData,
            childrenName: "",
            Joys: "",
            Joys2: "",
            favoritePlace: "",
            FamilyMemberAndRelationship: "",
            nickname: "",
            SecondfavoritePlace: "",
            Whynickname: "",
            FavouriteActivity: "",
            familyMemberOrFriend: "",
          };
        case "section2":
          return {
            ...prevData,
            tragicIncident: "",
            iSufferedfrom: "",
            Rescuers: "",
            guideAppeared: "",
            challengeFrequently: "",
            Hospital: "",
            activities: "",
            strengths: "",
            accident: "",
          };
        case "section3":
        case "section4":
          return {
            ...prevData,
          };
        default:
          return prevData;
      }
    });
    setFiles([]);
  };

  const renderSectionContent = () => {
    switch (activeSection) {
      case "section1":
        return <General formData={formData} setFormData={setFormData} />;
      case "section2":
        return (
          <Challenge
            formData={formData}
            error={error}
            setFormData={setFormData}
          />
        );
      case "section3":
        return (
          <Upload
            files={files}
            setFiles={setFiles}
            showError={showUploadError}
          />
        );
      case "section4":
        return <SuccessMessage />;
      default:
        return null;
    }
  };

  const isSubmitDisabled = () => {
    return activeSection === "section3"
      ? !captchaValue || files.length === 0 || loading
      : false;
  };

  return (
    <div className="form-page">
      <div className="form-container-page">
        <div className="form-first-pages">
          <div className="text-container-form">
            <span className="form-heading-text">
              Enroll in the Journey Book Story
              <br />
              Crafting Academy Today
            </span>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              width: "100%",
              height: "60vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={24} color="success" />
          </div>
        ) : (
          <div className="form-valid-container">
            {activeSection !== "section4" && (
              <div className="form-navigation">
                <div
                  className={`form-content-row-align ${
                    activeSection === "section1" ? "active" : ""
                  }`}
                >
                  {activeSection === "section1" || isSectionOne ? (
                    <TbCircleNumber1Filled
                      size={18}
                      style={{ color: "#008080" }}
                    />
                  ) : (
                    <TbCircleNumber1 size={18} style={{ color: "#BABEBE" }} />
                  )}
                  <span
                    className="form-header-text-align"
                    style={{
                      color:
                        activeSection === "section1" || isSectionOne
                          ? "#008080"
                          : "#BABEBE",
                    }}
                    onClick={() => setActiveSection("section1")}
                  >
                    General Information
                  </span>
                  <hr
                    className={`progress-line ${
                      activeSection === "section1" ||
                      activeSection === "section2"
                        ? "active"
                        : ""
                    }`}
                  />
                </div>
                <div
                  className={`form-content-row-align ${
                    activeSection === "section2" ? "active" : ""
                  }`}
                >
                  {activeSection === "section2" || isSectionTwo ? (
                    <TbCircleNumber2Filled
                      size={18}
                      style={{ color: "#008080" }}
                    />
                  ) : (
                    <TbCircleNumber2 size={18} style={{ color: "#BABEBE" }} />
                  )}
                  <span
                    className="form-header-text-align"
                    style={{
                      color:
                        activeSection === "section2" || isSectionTwo
                          ? "#008080"
                          : "#BABEBE",
                    }}
                    onClick={() => setActiveSection("section2")}
                  >
                    Challenges
                  </span>
                  <hr
                    className={`progress-line ${
                      activeSection === "section3" ? "active" : ""
                    }`}
                  />
                </div>
                <div
                  className={`form-content-row-align ${
                    activeSection === "section3" ? "active" : ""
                  }`}
                >
                  {activeSection === "section3" ? (
                    <TbCircleNumber3Filled
                      size={18}
                      style={{ color: "#008080" }}
                    />
                  ) : (
                    <TbCircleNumber3 size={18} style={{ color: "#BABEBE" }} />
                  )}
                  <span
                    className="form-header-text-align"
                    style={{
                      color:
                        activeSection === "section3" ? "#008080" : "#BABEBE",
                    }}
                    onClick={() => setActiveSection("section3")}
                  >
                    Upload Photos
                  </span>
                </div>
              </div>
            )}
            <div className="form-btn-box-container">
              <div className="form-body-content">{renderSectionContent()}</div>
              {activeSection === "section3" && (
                <div className="recaptacharef-text-align">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={SITE_KEY}
                    onChange={handleCaptchaChange}
                  />
                </div>
              )}
              {activeSection !== "section4" && (
                <div className="form-button-content">
                  <button
                    className="custom-clear-btn-form"
                    onClick={handleClearClick}
                  >
                    Clear
                  </button>
                  <button
                    className="custom-button-form-btn"
                    onClick={handleContinueClick}
                    disabled={isSubmitDisabled()}
                    style={{
                      backgroundColor: isSubmitDisabled()
                        ? "#d3d3d3"
                        : "#008080",
                      cursor: isSubmitDisabled() ? "not-allowed" : "pointer",
                    }}
                  >
                    {activeSection === "section3" ? "Submit" : "Continue"}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ marginTop: "30px" }}>
        <Footer />
      </div>
    </div>
  );
}

export default FormValid;
