import React, { useEffect, useState } from 'react';
import { Rating, Avatar } from '@mui/material';
import "../../style/home.css";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import iconsImg from '../../assets/images/vectorImg.png';

const testimonials = [
	{
		id: 1,
		icons: iconsImg,
		name: "Andrew James",
		profilePicture: "https://tse1.mm.bing.net/th?id=OIP.0h8fo76BwSZTehnXfYhcNQHaHc&pid=Api&P=0&h=220",
		rating: 5,
		textPlaceholder: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	},
	{
		id: 2,
		icons: iconsImg,
		name: "John Doe",
		profilePicture: "https://tse1.mm.bing.net/th?id=OIP.0h8fo76BwSZTehnXfYhcNQHaHc&pid=Api&P=0&h=220",
		rating: 5,
		textPlaceholder: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	},
	{
		id: 3,
		icons: iconsImg,
		name: "Jane Smith",
		profilePicture: "https://tse1.mm.bing.net/th?id=OIP.0h8fo76BwSZTehnXfYhcNQHaHc&pid=Api&P=0&h=220",
		rating: 2,
		textPlaceholder: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	},
	{
		id: 4,
		icons: iconsImg,
		name: "Alice Johnson",
		profilePicture: "https://tse1.mm.bing.net/th?id=OIP.0h8fo76BwSZTehnXfYhcNQHaHc&pid=Api&P=0&h=220",
		rating: 2,
		textPlaceholder: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	},
	{
		id: 5,
		icons: iconsImg,
		name: "Bob Brown",
		profilePicture: "https://tse1.mm.bing.net/th?id=OIP.0h8fo76BwSZTehnXfYhcNQHaHc&pid=Api&P=0&h=220",
		rating: 3,
		textPlaceholder: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	},
	{
		id: 6,
		icons: iconsImg,
		name: "Carol White",
		profilePicture: "https://tse1.mm.bing.net/th?id=OIP.0h8fo76BwSZTehnXfYhcNQHaHc&pid=Api&P=0&h=220",
		rating: 4,
		textPlaceholder: "Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
	}
];

function Testimonial() {
	const [currentPage, setCurrentPage] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(3);

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;
			if (width <= 767) {
				setItemsPerPage(1);
			} else {
				setItemsPerPage(3);
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const handlePrevious = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
	};

	const handleNext = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(testimonials.length / itemsPerPage) - 1));
	};

	const startIndex = currentPage * itemsPerPage;
	const selectedTestimonials = testimonials.slice(startIndex, startIndex + itemsPerPage);

	return (
		<div className="home-Testimonial-container">
			<div className="sub-Testimonial-container">
				<div className='Testimonial-content-container'>
					<span className='testimonial-heading-text'>
						Testimonials
					</span>
					<p className='testimonial-para-text'>
						Voices of Success: Our<br />
						Community's Testimonials
					</p>
				</div>
				<div className='card-view-profile-container'>
					{selectedTestimonials.map((testimonial) => (
						<div key={testimonial.id} className="testimonial-card">
							<div style={{ marginTop: '15px' }}>
								<img src={testimonial.icons} alt='icon' className='icon-img-testimonial-page' />
							</div>
							<div style={{ marginTop: '15px' }}>
								<p className='testimonial-card-para-text'>{testimonial.textPlaceholder}</p>
							</div>
							<Avatar
								alt={testimonial.name}
								src={testimonial.profilePicture}
								sx={{ width: 100, height: 100, marginTop: 2 }}
							>
								{testimonial.name.split(" ").map(n => n[0]).join("")}
							</Avatar>
							<div style={{ marginTop: '15px' }}>
								<span className='testimonial-card-heading-text'>{testimonial.name}</span>
							</div>
							<div style={{ marginTop: '15px' }}>
								<Rating
									name={`rating-${testimonial.id}`}
									value={testimonial.rating}
									readOnly
								/>
							</div>
						</div>
					))}
				</div>
				<div className="pagination">
					<button onClick={handlePrevious} disabled={currentPage === 0}>
						<FaArrowLeft size={16} />
					</button>
					<button onClick={handleNext} disabled={currentPage === Math.ceil(testimonials.length / itemsPerPage) - 1}>
						<FaArrowRight size={16} />
					</button>
				</div>
			</div>
		</div>
	);
}

export default Testimonial;
