import React from 'react'
import "../../style/home.css";

function Story() {
	return (
		<div className="home-story-container">
			<div className="sub-story-container">
				<div className='story-content-container'>
					<div>
						<p className='story-heading-color'>
							See Our Success Story
						</p>
					</div>
					<div>
						<p className='story-heading--para'>
							There are many variations of passages of agency<br />
							Lorem Ipsum Fasts injecte.
						</p>
					</div>
				</div>
			</div>
		</div >
	)
}

export default Story