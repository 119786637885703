import axios from "axios";
const BASE = "https://journeybookstories.com/api/v1/journey-book-stories";

const url = BASE;

export const handleFormSubmitted = (data) => {
  let response = axios.post(`${url}/story`, data);
  return response;
};

export const handleContactUs = (data) => {
  let response = axios.post(`${url}/contact-us`, data);
  return response;
};
