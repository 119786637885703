import React from 'react'
import "../../style/main.css";
import "../../style/form.css";
import FormValid from './FormValid';

function Form() {
	return (
		<div className='form-main-container'>
			<div className="body-component-form">
				<FormValid />
			</div>
		</div>
	)
}

export default Form