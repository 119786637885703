import React from 'react'
import "../../style/form.css";
import ThankYou from '../../assets/images/thankYou.png';
import { useNavigate } from 'react-router-dom';

function SuccessMessage() {
	const navigate = useNavigate()
	const handleClick = () => {
		navigate("/home");
	}
	return (
		<div className='first-form-container'>
			<div className="input-container-success">
				<div>
					<img src={ThankYou} width="250px" height="250px" alt="ThankYou" />
				</div>
				<div style={{ marginTop: '20px' }}>
					<p className='thankYou-page-para'>
						Congratulation your details Successfully<br />
						Uploaded
					</p>
				</div>
				<div style={{ marginTop: '20px' }}>
					<button onClick={handleClick} className="custom-button-ssuccess-btn">
						Back to Home
					</button>
				</div>
			</div>
		</div>
	)
}

export default SuccessMessage