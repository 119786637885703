import React, { useState } from "react";
import FAQImg from "../../assets/images/faq_img.jpg.png";
import OpenBox from "../../assets/images/OpenBox.png";
import ClosedBox from "../../assets/images/closedBox.png";
import "../../style/home.css";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";

function FAQ() {
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  const navigate = useNavigate();

  const handleClick = () => {
    ReactGA.event({
      category: "FAQ",
      action: "Navigate to FAQ page",
    });
    navigate("/faq");
  };
  const toggleCollapse1 = () => setIsOpen1(!isOpen1);
  const toggleCollapse2 = () => setIsOpen2(!isOpen2);
  const toggleCollapse3 = () => setIsOpen3(!isOpen3);

  return (
    <div className="home-faq-container">
      <div className="sub-faq-container">
        <div className="faq-content-container">
          <img src={FAQImg} className="img-faq-pages" alt="FAQ" />
        </div>
        <div className="faq-form-container">
          <div>
            <span className="faq-heading-text">
              Got Questions? Here Are Your
              <br />
              Answers - FAQ
            </span>
          </div>
          <div className="faq-form-box-container">
            <div className="collapsible">
              <button
                onClick={toggleCollapse1}
                className={`collapsible-button ${isOpen1 ? "open" : "closed"}`}
              >
                {isOpen1 ? (
                  <img
                    src={OpenBox}
                    className="arrow-faq-img-page"
                    alt="OpenBox"
                  />
                ) : (
                  <img
                    src={ClosedBox}
                    className="arrow-faq-img-page"
                    alt="ClosedBox"
                  />
                )}
                <span className="collapsible-text-heading">
                  Is there a way to expedite the printing and shipping process?{" "}
                </span>
                {isOpen1 ? (
                  <BiSolidUpArrow
                    size={"18px"}
                    color="#FFFFFF"
                    style={{ marginLeft: "auto" }}
                  />
                ) : (
                  <BiSolidDownArrow
                    size={"18px"}
                    color="#008080"
                    style={{ marginLeft: "auto" }}
                  />
                )}
              </button>
              {isOpen1 && (
                <div className="collapsible-content">
                  <p className="collapsible-para">
                    LoYes, we offer expedited shipping options for an additional
                    fee.
                    <br /> You can select these options during the checkout
                    process. Printing is limited to the demand of each of our
                    world wide locations.
                  </p>
                </div>
              )}
            </div>
            <div className="collapsible">
              <button
                onClick={toggleCollapse2}
                className={`collapsible-button ${isOpen2 ? "open" : "closed"}`}
              >
                {isOpen2 ? (
                  <img
                    src={OpenBox}
                    className="arrow-faq-img-page"
                    alt="OpenBox"
                  />
                ) : (
                  <img
                    src={ClosedBox}
                    className="arrow-faq-img-page"
                    alt="ClosedBox"
                  />
                )}
                <span className="collapsible-text-heading">
                  What if I need to make changes after submitting my book?
                </span>
                {isOpen2 ? (
                  <BiSolidUpArrow
                    size={"18px"}
                    color="#FFFFFF"
                    style={{ marginLeft: "auto" }}
                  />
                ) : (
                  <BiSolidDownArrow
                    size={"18px"}
                    color="#008080"
                    style={{ marginLeft: "auto" }}
                  />
                )}
              </button>
              {isOpen2 && (
                <div className="collapsible-content">
                  <p className="collapsible-para">
                    If you need to make changes after submission, you can
                    contact our support team for assistance. We aim to
                    accommodate changes before the book goes to print.
                  </p>
                </div>
              )}
            </div>
            <div className="collapsible">
              <button
                onClick={toggleCollapse3}
                className={`collapsible-button ${isOpen3 ? "open" : "closed"}`}
              >
                {isOpen3 ? (
                  <img
                    src={OpenBox}
                    className="arrow-faq-img-page"
                    alt="OpenBox"
                  />
                ) : (
                  <img
                    src={ClosedBox}
                    className="arrow-faq-img-page"
                    alt="ClosedBox"
                  />
                )}
                <span className="collapsible-text-heading">
                  How do I know if my book has been approved for printing?
                </span>
                {isOpen3 ? (
                  <BiSolidUpArrow
                    size={"18px"}
                    color="#FFFFFF"
                    style={{ marginLeft: "auto" }}
                  />
                ) : (
                  <BiSolidDownArrow
                    size={"18px"}
                    color="#008080"
                    style={{ marginLeft: "auto" }}
                  />
                )}
              </button>
              {isOpen3 && (
                <div className="collapsible-content">
                  <p className="collapsible-para">
                    You will receive an email notification once your book has
                    been reviewed and approved for printing. If any changes are
                    needed, we will contact you with specific feedback.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="btn-FAQ-align">
            <button className="custom-button-FAQ" onClick={handleClick}>
              MORE FAQ
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
