import React from "react";
import "../../style/faq.css";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FAQIMG from "../../assets/images/istockphotoFAQ.jpg";
import { FaQuestionCircle } from "react-icons/fa";

const FAQMain = () => {
  return (
    <div className="faq-main-page-container">
      <div className="faq-Sub-page-container">
        <div style={{ width: "100%" }}>
          <img
            src={FAQIMG}
            width="100%"
            height="600px"
            loading="lazy"
            alt="FAQ"
          />
        </div>
        <div className="faq-body-page-container">
          <div>
            <h1 className="faq-heading">Frequently Asked Questions</h1>
          </div>
          <div className="faqMain-form-box-container">
            <div className="faq-accordion-container">
              <div className="faq-accordion-container-sub">
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      What is the process for creating a Journey Book?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      Creating a Journey Book is simple and straightforward:
                      <ol>
                        <li>
                          Create an account or log into your existing account.
                        </li>
                        <li>Select from our available book styles.</li>
                        <li>
                          Upload up to 20 photos from your device, crop, and
                          resize them as needed.
                        </li>
                        <li>
                          Provide details about your journey using our guided
                          form or chatbot.
                        </li>
                        <li>
                          Review your book to ensure everything looks perfect.
                        </li>
                        <li>
                          Select your cover type (glossy or matte) and design.
                        </li>
                        <li>
                          Submit your book for review. After approval, we’ll
                          print and ship it to you.
                        </li>
                      </ol>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      How many photos can I include in my Journey Book?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      You can include up to 20 photos in your Journey Book.
                      These photos can be uploaded, cropped, and resized to fit
                      the designated spaces in your book.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      What options are available for book covers?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      We offer several cover options:
                      <ul>
                        <li>Glossy or Matte</li>
                        <li>
                          Various cover designs to choose from, each tailored to
                          different themes and aesthetics.
                        </li>
                      </ul>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      What is the illustration theme for the interior of the
                      book?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      The interior of the Journey Book features a compassionate
                      and uplifting illustration theme designed to resonate with
                      readers of all ages. The illustrations have various
                      Journey Themes to match the specific journey being
                      documented.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      Can I choose between a glossy or matte cover?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      Yes, you can choose either a glossy or matte finish for
                      your book cover. Both options provide a high-quality look
                      and feel. Our client testimonials like the Matte for these
                      reasons and the Glossy for these reasons.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      What is the timing for receiving my Journey Book?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      Once your book is submitted and approved, it takes 3-7
                      days for printing. Shipping times vary based on your
                      location and the shipping method chosen, but typically it
                      takes an additional 3-7 days.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      How many copies do I get with my order?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      Your initial order includes three copies of your Journey
                      Book. You can order additional copies as needed.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="faq-accordion-container-sub">
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      Can I reorder additional copies?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      Absolutely! Once your book is created, you can reorder
                      additional copies at any time through your account.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      What if I need to make changes after submitting my book?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      If you need to make changes after submission, you can
                      contact our support team for assistance. We aim to
                      accommodate changes before the book goes to print.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      How do I know if my book has been approved for printing?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      You will receive an email notification once your book has
                      been reviewed and approved for printing. If any changes
                      are needed, we will contact you with specific feedback.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      What is the cost of creating a Journey Book?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      The cost varies based on the book style, cover options,
                      and number of pages. Our pricing is competitive, and we
                      aim to provide an affordable option for all families.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      Can I customize the text and illustrations in the book?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      Yes, you can customize both the text and illustration
                      theme to ensure the book accurately reflects your unique
                      journey.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="faq-accordion">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="faq-question">
                      <FaQuestionCircle style={{ marginRight: "8px" }} />
                      Is there a way to expedite the printing and shipping
                      process?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography className="faq-answer">
                      Yes, we offer expedited shipping options for an additional
                      fee. You can select these options during the checkout
                      process. Printing is limited to the demand of each of our
                      world wide locations.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="notes-container">
            <span className="note-heading-text">Note :</span>
            <p className="note-para-text">
              This is a starter FAQ and can be updated with more specific
              details and additional questions as needed. This format will help
              provide clear and concise information to your customers, enhancing
              their experience with the Journey Book project.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQMain;
