import React from "react";
import "../../style/privacypolicy.css";
import PrivacypolicyIMG from "../../assets/images/Privacy-Policy-Banner-New.webp";

const Privacypolicy = () => {
  return (
    <div className="Privacypolicy-Sub-page-container">
      <div className="Privacypolicy-Sub-page-container">
        <div style={{ width: "100%" }}>
          <img
            src={PrivacypolicyIMG}
            width="100%"
            height="500px"
            loading="lazy"
            alt="PrivacypolicyIMG"
          />
        </div>
        <div className="privacypolicy-body-container">
          <h1 className="privacypolicy-heading">Privacy Policy</h1>
          <div className="privacypolicy-content-container">
            <p className="privacypolicy-content">
              {/* Add your privacy policy content here */}
              This Privacy Policy explains how we collect, use, and protect your
              personal information when you use our services. We are committed
              to ensuring your privacy and the protection of your personal data.
              If you have any questions or concerns, please contact us.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
