import React, { useState } from "react";
import { MdDelete } from "react-icons/md";
import FileUploadIcon from "../../assets/images/folder-open.png";
import DocumentIcon from "../../assets/images/document.png";
import "../../style/form.css";

function Upload({ showError, files, setFiles }) {
  const [isDragging, setIsDragging] = useState(false);

  const convertToJPEG = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          resolve(
            new File([blob], file.name.replace(/\..+$/, ".jpg"), {
              type: "image/jpeg",
            })
          );
        }, "image/jpeg");
      };
      img.onerror = (err) => reject(err);
    });
  };

  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    const filesWithPreview = await Promise.all(
      selectedFiles.map(async (file) => {
        const convertedFile = file.type.startsWith("image/")
          ? await convertToJPEG(file)
          : file;
        return {
          file: convertedFile,
          preview: URL.createObjectURL(convertedFile),
        };
      })
    );
    setFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);
  };

  const handleFileRemove = (indexToRemove) => {
    setFiles((prevFiles) => {
      const newFiles = prevFiles.filter((_, index) => index !== indexToRemove);
      URL.revokeObjectURL(prevFiles[indexToRemove].preview); // Clean up URL object
      return newFiles;
    });
  };

  const getFileIcon = (file) => {
    if (!file || !file.type) return DocumentIcon; // Add this check
    const fileType = file.type.split("/")[0];
    if (fileType === "image") {
      return file.preview;
    }
    return DocumentIcon;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    const filesWithPreview = await Promise.all(
      droppedFiles.map(async (file) => {
        const convertedFile = file.type.startsWith("image/")
          ? await convertToJPEG(file)
          : file;
        return {
          file: convertedFile,
          preview: URL.createObjectURL(convertedFile),
        };
      })
    );
    setFiles((prevFiles) => [...prevFiles, ...filesWithPreview]);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div
        className={`file-upload-container ${
          showError && files.length === 0 ? "error-border" : ""
        } ${isDragging ? "dragging" : ""}`}
        onClick={() => document.getElementById("fileInput").click()}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <img
          src={FileUploadIcon}
          width="40px"
          height="40px"
          alt="Upload Icon"
        />
        <span className="upload-heading-text">
          Choose a file or drag & drop it here
        </span>
        <p className="para-text-upload">JPG, PNG, SVG, JPEG Max.size is 50MB</p>
        <div className="browse-file-container-box">
          <span className="browse-file-text-align">Browse File</span>
        </div>
      </div>
      <input
        type="file"
        id="fileInput"
        multiple
        onChange={handleFileChange}
        style={{
          width: "0.1px",
          height: "0.1px",
          opacity: 0,
          overflow: "hidden",
          position: "absolute",
          zIndex: -1,
        }}
      />
      {showError && files.length === 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <p
            style={{
              color: "#FE1D1D",
              fontFamily: "Inter",
              fontWeight: 400,
              fontSize: "14px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            All fields are required.
          </p>
        </div>
      )}
      <div style={{ maxHeight: "150px", overflowY: "auto", marginTop: "20px" }}>
        {files.map((fileObj, index) => (
          <div key={index} className="card-upload-img-align">
            <div style={{ display: "flex", alignItems: "center" }}>
              {fileObj.file && fileObj.file.type.startsWith("image/") ? (
                <img
                  src={fileObj.preview}
                  width="32px"
                  height="32px"
                  alt="File Preview"
                  style={{ marginRight: "10px", objectFit: "cover" }}
                />
              ) : (
                <img
                  src={getFileIcon(fileObj.file)}
                  width="32px"
                  height="32px"
                  alt="File Icon"
                  style={{ marginRight: "10px" }}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <p
                  style={{
                    color: "#000000",
                    fontFamily: "Inter",
                    fontWeight: 500,
                    fontSize: "14px",
                    margin: 0,
                  }}
                >
                  {fileObj.file ? fileObj.file.name : "Unknown File"}
                </p>
                <p
                  style={{
                    color: "#BABEBE",
                    fontFamily: "Inter",
                    fontWeight: 400,
                    fontSize: "12px",
                    margin: 0,
                  }}
                >
                  {fileObj.file ? `${fileObj.file.size} bytes` : ""}
                </p>
              </div>
            </div>
            <MdDelete
              size={"24px"}
              color={"#BABEBE"}
              style={{ cursor: "pointer" }}
              onClick={() => handleFileRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Upload;
