import React, { useState, useEffect } from "react";
import "../../style/headNav.css";
import JourneyBooks from "../../assets/images/Journey_Books.png-removebg-preview.png";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Menus } from "./menu";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, MenuItem, Menu } from "@mui/material";

function HeadNav() {
  const location = useLocation();
  const path = location.pathname.split("/");
  const [navTitle, setNavTitle] = useState(path[path.length - 1]);
  const [open, setOpen] = useState(false);

  const handleClick = (event) => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickNav = (title) => {
    setNavTitle(title);
    setOpen(false);
  };

  useEffect(() => {
    setNavTitle(path[path.length - 1]);
  }, [path]);

  const handleDemoPDFClick = () => {
    window.open(
      "https://flipbooks.journeybookstories.com/index.html",
      "_blank"
    );
  };

  return (
    <div className="header-main">
      <div className="jeurney-logo">
        <Link to="home">
          <img
            src={JourneyBooks}
            onClick={() => setNavTitle("home")}
            className="logo-header-img"
            alt="JourneyBooks"
          />
        </Link>
      </div>
      <div className="top-navbar">
        <nav className="nav-menu">
          <div className="nav-bar">
            <div className="nav-menu-item">
              {Menus?.map((item, index) => (
                <div key={index} className={item.cName}>
                  <NavLink to={item.path} className="link">
                    <div
                      className={`title-name active-nav ${
                        navTitle === item.path ? "clicked-nav" : ""
                      }`}
                      onClick={() => handleClickNav(item.path)}
                    >
                      {item.title}
                    </div>
                  </NavLink>
                </div>
              ))}
            </div>
            <div className="dtds-navbar-toggle">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <MenuIcon fontSize="medium" />
              </IconButton>
              <Menu
                onClose={handleClose}
                id="account-menu"
                open={open}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 8.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 2,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}
              >
                {Menus?.map((item, index) => {
                  return (
                    <MenuItem key={index} onClick={handleClose}>
                      <NavLink
                        to={item.path}
                        className="link"
                        onClick={handleClick}
                      >
                        {item.title}
                      </NavLink>
                    </MenuItem>
                  );
                })}
                <MenuItem>
                  <button
                    onClick={handleDemoPDFClick}
                    className="demo-pdf-button"
                  >
                    Demo PDF
                  </button>
                </MenuItem>
              </Menu>
            </div>
            <div className="container-demo-pdf-button">
              <button onClick={handleDemoPDFClick} className="demo-pdf-button">
                Demo PDF
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default HeadNav;
